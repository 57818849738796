/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import styles from "@/styles/mobile_best_sellers.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { homeLinks } from "@/data/home";
import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import ProductCard from "../product-card/product-card";
import arrow from "../../assets/images/arrow.png";
import { getCookie } from "cookies-next";
import { unbxdTrackEvent } from "@/lib/unbxd";

SwiperCore.use([Navigation, Pagination]);

const Mobile_Best_Sellers = ({ product }: any) => {
  const [data, setData] = useState<any>([]);
  const [title, setTitle] = useState("");
  const [requestId, setrequestId] = useState(
    product?.headers?.["unbxd-request-id"]
  );

  useEffect(() => {
    loadCustomerFavourite();
  }, []);

  const loadCustomerFavourite = async () => {
    const products =
      product.data.widget3?.recommendations.map((product: any) => ({
        id: product?.productId,
        title: product?.title,
        slug: product?.productUrl.split("/")[
          product?.productUrl.split("/").length - 1
        ],
        sku: product?.uniqueId,
        try_online: product?.hasTryOnline?.toLowerCase() === "true",
        image: product?.imageUrl[0],
        priceId: product.variantId,
        price: product?.price,
        discount: {
          total: product?.discount ? product?.sellingPrice : null,
          name: product?.discountLabel || null,
          price: product?.discount ? product?.sellingPrice : null,
          text: product?.discountLabel || null,
        },
        fastDelivery: product?.vIsFastDelivery?.toLowerCase() === "true",
        // deal_of_the_day: null,
        categories: [{ name: product.categoryPath1[0] }],
        // trending: null,
        // featured: null,
        // recomemded: null,
        best_seller: product?.isBestSeller?.toLowerCase() === "true",
        new_arrival: product?.isNewArrival?.toLowerCase() === "true",
      })) || [];
    setData(products);
    setTitle(product.data.widget3.widgetTitle);
    if (products.length) {
      const payload = {
        requestId: requestId,
        pids_list: products.map((v: any) => v.sku),
        experience_pagetype: "HOME",
        experience_widget: "WIDGET3",
      };
      unbxdTrackEvent("experience_impression", payload);
    }
  };
  return (
    <Fragment>
      {!!data.length && (
        <section className={styles.best_sellers}>
          <div className="container best-sellers-d">
            <div className={styles.best_sellersheader}>
              <div className="row">
                <div className="col-md-12">
                  <div className={styles.h2}>{title}</div>
                </div>
              </div>
              <div className={styles.best_sellers_main}>
                <Swiper
                  className="swiper-navigation-black"
                  navigation={true}
                  spaceBetween={10}
                  slidesPerView={2}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 4,
                    },
                    1200: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {data.map((v: any, i: any) => (
                    <SwiperSlide key={i}>
                      <div className={styles.jwellery}>
                        <ProductCard
                          product={v}
                          unbxd={{
                            isunbxd: true,
                            requestId: requestId,
                            experience_pagetype: "HOME",
                            experience_widget: "WIDGET3",
                          }}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Link href="/customers-favourite" passHref>
                    <div className={styles.btn_get_started}>
                      SEE ALL Products
                      <Image
                        src={arrow}
                        className="img-fluid"
                        alt="features10"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default Mobile_Best_Sellers;
